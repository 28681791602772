<template>
    <PModal :footer-actions="footerActions" slot-class="p-0 flex-grow-1">
        <template #title>{{ $l("platon.image_cropper_title", "Расмни таҳрирлаш") }}</template>

        <div style="width: 100%; text-align: center">
            <img :src="imageUrl" ref="image" style="display: block; max-width: 100%" />
        </div>
    </PModal>
</template>

<script>
import Cropper from "cropperjs"
import "cropperjs/dist/cropper.css"
import { blobToFile, fileToBase64 } from "@Platon/core/helpers/FileHelpers"

export default {
    name: "ImageCropper",

    props: {
        file: {
            type: File
        },

        onCrop: {
            type: Function
        },

        cropperOptions: {
            type: Object
        }
    },

    data() {
        return {
            imageUrl: null,
            cropper: null
        }
    },

    mounted() {},

    methods: {
        async initCropper() {
            await this.$nextTick()

            this.cropper = new Cropper(this.$refs.image, {
                viewMode: "2",
                ...this.cropperOptions
            })
        },

        crop() {
            this.cropper.getCroppedCanvas().toBlob((blob) => {
                if (typeof this.onCrop === "function") {
                    this.onCrop(blobToFile(blob, this.file.name, this.file.type))
                }
            }, this.file.type)
        }
    },

    computed: {
        /** {ModalAction[]} */
        footerActions() {
            return [
                { text: this.$l("platon.save", "Сақлаш"), icon: "fa fa-crop", handler: this.crop },
                { text: this.$l("platon.cancel", "Бекор қилиш"), icon: "fa fa-times" }
            ]
        }
    },

    watch: {
        file: {
            immediate: true,
            handler(file) {
                if (file)
                    fileToBase64(file).then((f) => {
                        this.imageUrl = f
                    })
            }
        },

        imageUrl: {
            handler() {
                this.initCropper()
            }
        }
    }
}
</script>
